import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BlogPostHero from "../components/BlogPostHero"
import BlogPostBody from "../components/BlogPostBody"

export default function BlogPostTemplate({ data }) {
  const {
    mdx: {
      frontmatter: { title, date, category, cover },
      body,
      excerpt
    },
  } = data

  return (
    <Layout darkText>
      <Seo title={title} description={excerpt} />
      <div style={{ maxWidth: '1500px', marginLeft: 'auto', marginRight: 'auto'}}>
        <BlogPostHero category={category} date={date} title={title} />
        <GatsbyImage image={getImage(cover)} alt={title} />
        <BlogPostBody>
          <MDXRenderer>{body}</MDXRenderer>
        </BlogPostBody>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        cover {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        title
        date
        category
      }
      body
      excerpt
    }
  }
`
