import React from "react"
import styled from "styled-components"
import dotPattern from "../images/dot-pattern.svg"

const Body = styled.article`
  padding: 0 32px 96px;

  @media (min-width: 1024px) {
    padding: 0 0 128px 104px;
  }

  @media (min-width: 1280px) {
    padding: 0 0 156px 0;
  }

  h4 {
    margin-bottom: 24px;
    max-width: 1024px;
    @media (min-width: 1024px) {
      margin-right: 32px;
    }

    @media (min-width: 1280px) {
      margin-bottom: 32px;
      margin-right: 104px;
    }
  }

  p {
    line-height: 134%;
    max-width: 1024px;

    @media (min-width: 1024px) {
      margin-right: 32px;
    }

    @media (min-width: 1280px) {
      margin-right: 104px;
    }
  }

  .gatsby-resp-image-wrapper {
    margin-right: -32px !important;
    max-width: 1232px !important;
    position: relative;

    @media (min-width: 1280px) {
      margin-right: -104px !important;

      &::before {
        content: "";
        position: absolute;
        right: -30px;
        top: -52px;
        width: 116px;
        height: 116px;
        background: url(${dotPattern}) no-repeat;
        z-index: 999;
      }

      &::after {
        content: "";
        position: absolute;
        left: -110px;
        bottom: 60px;
        width: 116px;
        height: 116px;
        background: url(${dotPattern}) no-repeat;
        z-index: 999;
      }
    }
  }
`

export default function BlogPostBody({ children }) {
  return <Body>{children}</Body>
}
